export default [
                      
  { name: 'WE時代', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '最Win地段', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', mobileOffset: 0 },
  { name: '最Wee建築', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', mobileOffset: 0 },
  //{ name: '最WOW建材', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', mobileOffset: 0 },
  //{ name: '最WE Style的家', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', mobileOffset: 0 },
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'orange' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'blue' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'green' },
]
